import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.renderPickers();
  }

  renderPickers() {
    $('select.image-picker')
      .imagepicker({
        // hide_select:  false,
        limit_reached() {
          alert('You only need to choose two scenes.');
        },
        selected() {
          console.log($(this).data('picker'));
          $(this)
            .data('picker')
            .sync_picker_with_select();
        },
      });
  }

  rerenderPickers() {
    // Destroy previous pickers
    $.each($('select.image-picker'), (index, value) => {
      $(value)
        .data('picker')
        .destroy();
    });

    // Get templates data from endpoint
    const _this = this;
    const type = $('select#artwork_template_type option:selected')
      .val();
    $.get(`/environment_templates/templates?type=${type}`, (data) => {
      // Rerender select element and options
      // Regular templates
      let options = [];
      $.each(data.regular, (index, value) => {
        options.push(`<option value="${value.id}" data-img-src="${value.image_url}"></option>`);
      });
      const $regular = $('select#artwork_regular_templates');
      $regular.find('option')
        .remove();
      $regular.append(options.join(''));

      // Model templates
      options = [];
      $.each(data.model, (index, value) => {
        options.push(`<option value="${value.id}" data-img-src="${value.image_url}"></option>`);
      });
      const $model = $('select#artwork_model_templates');
      $model.find('option')
        .remove();
      $model.append(options.join(''));

      // Rerender pickers
      _this.renderPickers();
    });
  }
}
