import ApplicationController from './application_controller';

export default class extends ApplicationController {

  render_royalties_chart(e) {
    e.preventDefault();
    const reflex = this.data.get('reflex');
    if (reflex == null) return false;

    if (reflex === 'DashboardRoyaltiesReflex#render_custom_range_royalties_chart') {
      $('a[data-royalties-chart-beginning-at]').attr('data-royalties-chart-beginning-at', $('#royalties_chart_beginning_at').val());
      $('a[data-royalties-chart-end-at]').attr('data-royalties-chart-end-at', $('#royalties_chart_end_at').val());
    }

    const royalties_overview_widget_spinner = $('#royalties_overview_widget_spinner');
    royalties_overview_widget_spinner.show();
    $('#total_royalties, #royalties_overview_widget_result').html('');

    this.stimulate(reflex).then(() => {
      royalties_overview_widget_spinner.hide();
    });

  }

}
