import ApplicationController from './application_controller';

export default class extends ApplicationController {
  submit(e) {
    e.preventDefault();
    $('#error_explanation').hide();
    $('.alert').hide();

    this.stimulate(this.data.get('reflex')).then(() => {
    });
  }
}
