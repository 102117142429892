import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.checkForRedirectFrom();
  }

  checkForRedirectFrom() {
    if (window.location.href.indexOf('redirected_from=pending') > -1) {
      const _this = $('.artwork-submission-update-page .tabs-btns-area ul li:last-child a');
      const t = $(_this).data('toggle-target');
      if (t != null && (t == 'submit_for_review_form' || t == 'approval_form')) {
        this.activateTab(_this);
      }
    }
  }

  // For switching between tabs
  toggleTab(e) {
    const _this = this.element;
    this.activateTab(_this);
  }

  activateTab(_this) {
    const t = $(_this).data('toggle-target');
    if (t == null) return false;

    $('.artwork-toggle-section').hide();
    $(`.artwork-toggle-section[data-toggle-id=${t}]`).fadeIn('slow');
    $('.artwork-submission-update-page .tabs-btns-area ul li').removeClass('active');
    $(_this).parent('li').addClass('active');
  }

}
