import ApplicationController from './application_controller';

export default class extends ApplicationController {

  render_widget(e) {
    try {
      e.preventDefault();
      const reflex = this.data.get('reflex');
      const widget_id = this.data.get('widget-id');
      if (reflex == null || widget_id == null) return false;

      const spinner = $(`${widget_id} .spinner-section`);
      spinner.show();
      $(`${widget_id} .widget-title, ${widget_id} .widget-amount`).html('');

      this.stimulate(reflex).then(() => {
        spinner.hide();
      });
    } catch (err) {
      alert(err.message);
    }
    return true;
  }

  render_more_unique_links(e) {
    try {
      e.preventDefault();
      const reflex = this.data.get('reflex');
      if (reflex == null) return false;

      const element_unique_link = $('ul#generate_unique_link_data');
      const existing_uniqe_links = element_unique_link.html();
      const _this = this;
      const spinner = $('#generate_unique_link_widget .spinner-section');
      spinner.show();

      this.stimulate(reflex).then(() => {
        element_unique_link.prepend(existing_uniqe_links);
        spinner.hide();

        if (parseInt(_this.data.get('current-page')) >= parseInt(_this.data.get('total-pages'))) {
          $('#unique_link_data_load_more_btn').hide();
        } else {
          _this.data.set('current-page', parseInt(_this.data.get('current-page')) + 1);
        }

      });
    } catch (err) {
      alert(err.message);
    }
    return true;
  }

  render_shopify_collection_list(e) {
    try {
      e.preventDefault();
      const reflex = this.data.get('reflex');
      const shopify_collection_list_sort_by = $('#shopify_collection_list_sort_by').val();
      if (reflex == null || shopify_collection_list_sort_by == null) return false;

      this.data.set('sort-by', shopify_collection_list_sort_by);
      const spinner = $('#top_selling_block .spinner-section');
      spinner.show();

      this.stimulate(reflex).then(() => {
        spinner.hide();
      });
    } catch (err) {
      alert(err.message);
    }
    return true;
  }

}
