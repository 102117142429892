import { Controller } from 'stimulus';
import PDFObject from 'pdfobject';

export default class extends Controller {
  connect() {
    const options = {
      width: '100%',
      height: '500px',
    };
    PDFObject.embed('/pdfs/fw9.pdf', '#w9-pdf-container', options);
  }
}
