import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.imageValidator();
  }

  imageValidator() {
    const input = document.getElementById('artwork_file');
    const disabledButtonHelpText = document.getElementById('disabled-btn-help-text');
    const disabledFileSizeButtonHelpText = document.getElementById('disabled-btn-file-size-help-text');
    const disabledFileTypeButtonHelpText = document.getElementById('disabled-btn-file-type-help-text');
    // const imageHelpText = d
    const _this = this;

    input.addEventListener('change', function () {
      $('#artwork_file_hidden_input').val('');// set direct upload file input field initial value
      const file = this.files[0];
      const formData = new FormData();
      formData.append('Filedata', file);
      const t = file.type.split('/')
        .pop()
        .toLowerCase();

      // Check file size
      if (t !== 'png' && t !== 'jpeg') {
        _this.disableSubmitButton();
        _this.show(disabledFileTypeButtonHelpText);
        _this.hide(disabledFileSizeButtonHelpText);
        _this.hide(disabledButtonHelpText);
        _this.disableTemplateArea();
        return;
      }

      const img = new Image();
      img.onload = function () {
        const sizes = {
          width: this.width,
          height: this.height,
        };
        URL.revokeObjectURL(this.src);

        // Check file pixels width/height
        if (_this.sizeToSmall(sizes)) {
          _this.disableSubmitButton();
          _this.show(disabledButtonHelpText);
          _this.hide(disabledFileSizeButtonHelpText);
          _this.hide(disabledFileTypeButtonHelpText);
          _this.disableTemplateArea();
          return;
        }

        // Must be less than 150MB
        if (file.size > (1048576 * 150)) {
          _this.disableSubmitButton();
          _this.show(disabledFileSizeButtonHelpText);
          _this.hide(disabledButtonHelpText);
          _this.hide(disabledFileTypeButtonHelpText);
          _this.disableTemplateArea();
          return;
        }

        // Must have template type
        const templateType = _this.determineTemplateType(sizes);
        if (!templateType) {
          _this.disableTemplateArea();
          _this.disableSubmitButton();
          return;
        }

        // Enable submit
        _this.enableTemplateArea(templateType);
        _this.hide(disabledFileSizeButtonHelpText);
        _this.hide(disabledButtonHelpText);
        _this.hide(disabledFileTypeButtonHelpText);
        _this.enableSubmitButton(_this.renderFileName(input, 20));
      };

      img.src = URL.createObjectURL(file);
    });
  }

  hide(el) {
    el.classList.remove('show-text');
  }

  show(el) {
    el.classList.add('show-text');
  }

  disableTemplateArea() {
    const templateAreas = document.querySelectorAll('.templates-area');
    const templateSelect = document.getElementById('artwork_template_type');
    const templateHelpText = document.getElementById('artwork_template_help_text');

    // Hide template section
    templateAreas.forEach(area => area.classList.add('hide'));
    // Clear template type
    templateSelect.value = '';
    // Show any help text
    templateHelpText.classList.remove('hide');
  }

  enableTemplateArea(templateType) {
    const templateAreas = document.querySelectorAll('.templates-area');
    const templateSelect = document.getElementById('artwork_template_type');
    const templateHelpText = document.getElementById('artwork_template_help_text');

    // Show template section
    templateAreas.forEach(area => area.classList.remove('hide'));
    // Auto select the template type
    templateSelect.value = templateType.type;
    templateSelect.dispatchEvent(new Event('change'));
    // Hide any help text
    templateHelpText.classList.add('hide');
  }

  disableSubmitButton() {
    const submitButton = document.querySelector('button.theme-btn');
    submitButton.setAttribute('disabled', 'disabled');
    submitButton.classList.add('disabled');
    this.disableFileUploadField();
  }

  enableSubmitButton(file = '') {
    const submitButton = document.querySelector('button.theme-btn');
    submitButton.removeAttribute('disabled');
    submitButton.classList.remove('disabled');
    this.enableFileUploadField(file);
  }

  disableFileUploadField() {
    const fileUploadField = document.querySelector('.uplod-file-container');
    const fileNameTxt = document.querySelector('.uplod-file-container .upload-box-txt');
    fileUploadField.classList.remove('has-file-media');
    fileNameTxt.innerHTML = 'Drop your file here or <b>browse</b>';
  }

  enableFileUploadField(file = '') {
    const fileUploadField = document.querySelector('.uplod-file-container');
    const fileNameTxt = document.querySelector('.uplod-file-container .upload-box-txt');
    fileUploadField.classList.add('has-file-media');
    fileNameTxt.innerHTML = `Current File: <b>${file}</b></br>Drop your new file here or <b>browse</b>`;
  }

  sizeToSmall(sizes) {
    let types = Object.values(this.imageTemplateSizes());

    // Select all those template types where type width is smaller then current image width
    // Select all those template types where type height is smaller then current image height
    types = types.filter(type => type.width <= sizes.width && type.height <= sizes.height);

    // if none, return true
    // If some, return false
    return types.length === 0;
  }

  determineTemplateType(imageSizes) {
    const sizes = this.imageTemplateSizes();
    const ratios = [
      { type: 'vertical', ratio: sizes.vertical.width / sizes.vertical.height },
      { type: 'horizontal', ratio: sizes.horizontal.width / sizes.horizontal.height },
      { type: 'square', ratio: sizes.square.width / sizes.square.height },
      { type: 'wide', ratio: sizes.wide.width / sizes.wide.height },
      { type: 'tall', ratio: sizes.tall.width / sizes.tall.height },
    ];
    const imageRatio = imageSizes.width / imageSizes.height;

    return ratios.find(r => r.ratio === imageRatio);
  }

  imageTemplateSizes() {
    return {
      vertical: {
        width: 6750,
        height: 9000,
      },
      horizontal: {
        width: 9000,
        height: 6750,
      },
      square: {
        width: 6000,
        height: 6000,
      },
      wide: {
        width: 9000,
        height: 4500,
      },
      tall: {
        width: 4500,
        height: 9000,
      },
    };
  }

  renderFileName(input = null, text_limit = 40) {
    let file_name = input.value.split('\\').pop();
    if (file_name.length > text_limit)
      file_name = file_name.substring(0, text_limit) + '...';
    return file_name;
  }

}
