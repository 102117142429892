import { Controller } from 'stimulus';
import { DirectUpload } from '@rails/activestorage';

export default class extends Controller {
  connect() {
    this.imageValidator();
  }

  imageValidator() {
    const input = document.getElementById('multi_files_input');
    const _this = this;
    input.addEventListener('change', function () {
      _this.disableFileUploadField();
      let names = [];
      if (input.files.length != 0) {
        for (let i = 0; i < input.files.length; ++i) {
          names.push(input.files[i].name);
        }
        _this.enableFileUploadField(_this.renderFileName(JSON.stringify(names), 20));
      }
    });
  }

  renderFileName(file_names = '', text_limit = 40) {
    if (file_names.length > text_limit)
      file_names = '["' + file_names.substring(2, text_limit) + '..."]';
    return file_names;
  }

  disableFileUploadField() {
    const fileUploadField = document.querySelector('.uplod-file-container');
    const fileNameTxt = document.querySelector('.uplod-file-container .upload-box-txt');
    fileUploadField.classList.remove('has-file-media');
    fileNameTxt.innerHTML = 'Drop your file here or <b>browse</b>';
  }

  enableFileUploadField(file = '') {
    const fileUploadField = document.querySelector('.uplod-file-container');
    const fileNameTxt = document.querySelector('.uplod-file-container .upload-box-txt');
    fileUploadField.classList.add('has-file-media');
    fileNameTxt.innerHTML = `Current Files: <b>${file}</b></br>Drop your new file here or <b>browse</b>`;
  }

  disableTemplateArea(formId = '') {
    const templateAreas = $(`${formId} .templates-area`);
    const templateSelect = $(`${formId} .template-type-select select`);
    const templateHelpText = $(`${formId} .artwork_template_help_text`);

    // Hide template section
    templateAreas.each(function (index) {
      $(this).addClass('hide');
    });
    // Clear template type
    templateSelect.val('');
    // Show any help text
    templateHelpText.removeClass('hide');
  }

  enableTemplateArea(templateType, formId = '') {
    const templateAreas = $(`${formId} .templates-area`);
    const templateSelect = $(`${formId} .template-type-select select`);
    const templateHelpText = $(`${formId} .artwork_template_help_text`);

    // Show template section
    templateAreas.each(function (index) {
      $(this).removeClass('hide');
    });
    // Auto select the template type
    templateSelect.val(templateType.type).click();
    // Hide any help text
    templateHelpText.addClass('hide');
  }

  disableSubmitButton(submitButtonSelector = 'button.theme-btn') {
    const submitButton = document.querySelector(submitButtonSelector);
    submitButton.setAttribute('disabled', 'disabled');
    submitButton.classList.add('disabled');
  }

  enableSubmitButton(submitButtonSelector = 'button.theme-btn') {
    const submitButton = document.querySelector(submitButtonSelector);
    submitButton.removeAttribute('disabled');
    submitButton.classList.remove('disabled');
  }

  sizeToSmall(sizes) {
    let types = Object.values(this.imageTemplateSizes());

    // Select all those template types where type width is smaller then current image width
    // Select all those template types where type height is smaller then current image height
    types = types.filter(type => type.width <= sizes.width && type.height <= sizes.height);

    // if none, return true
    // If some, return false
    return types.length === 0;
  }

  determineTemplateType(imageSizes) {
    const sizes = this.imageTemplateSizes();
    const ratios = [
      { type: 'vertical', ratio: sizes.vertical.width / sizes.vertical.height },
      { type: 'horizontal', ratio: sizes.horizontal.width / sizes.horizontal.height },
      { type: 'square', ratio: sizes.square.width / sizes.square.height },
      { type: 'wide', ratio: sizes.wide.width / sizes.wide.height },
      { type: 'tall', ratio: sizes.tall.width / sizes.tall.height },
    ];
    const imageRatio = imageSizes.width / imageSizes.height;

    return ratios.find(r => r.ratio === imageRatio);
  }

  imageTemplateSizes() {
    return {
      vertical: {
        width: 13500,
        height: 18000,
      },
      horizontal: {
        width: 18000,
        height: 13500,
      },
      square: {
        width: 12000,
        height: 12000,
      },
      wide: {
        width: 18000,
        height: 9000,
      },
      tall: {
        width: 9000,
        height: 18000,
      },
    };
  }

  // For trigger tabs
  triggerTabs(e) {
    e.preventDefault();
    // Read selected files
    $('#multi-files-input-err').hide();
    const input = document.getElementById('multi_files_input');
    const totalfiles = input.files.length;
    if (totalfiles) {
      for (let index = 0; index < totalfiles; index++) {
        document.getElementById('add-tab').click();
        this.bulkImageValidator(input.files[index], `#tab${(index + 1)}C`);
      }
    } else {
      $('#multi-files-input-err').show();
    }
  }

  // For validate images
  bulkImageValidator(file, formId) {
    let status = true;
    const prependText = `${file.name} is not an accepted artwork file. `;
    const _this = this;
    _this.disableSubmitButton(`${formId} button.theme-btn`);
    _this.disableTemplateArea(formId);
    const formData = new FormData();
    formData.append('Filedata', file);
    const t = file.type.split('/')
      .pop()
      .toLowerCase();
    // Check file size
    if (t !== 'png' && t !== 'jpeg') {
      status = false;
      $(formId).html($('#single-form [data-id=\'disabled-btn-file-type-help-text\']').clone().addClass('show-text').prepend(prependText));
    } else {
      const img = new Image();
      img.onload = function () {
        const sizes = {
          width: this.width,
          height: this.height,
        };
        // Check file pixels width/height
        if (status && _this.sizeToSmall(sizes)) {
          status = false;
          $(formId).html($('#single-form [data-id=\'disabled-btn-help-text\']').clone().addClass('show-text').prepend(prependText));
        }

        // Must be less than 150MB
        if (status && file.size > (1048576 * 150)) {
          status = false;
          $(formId).html($('#single-form [data-id=\'disabled-btn-file-size-help-text\']').clone().addClass('show-text').prepend(prependText));
        }

        // Must have template type
        const templateType = _this.determineTemplateType(sizes);
        if (status && !templateType) {
          status = false;
          $(formId).html($('#single-form [data-id=\'artwork_template_help_text\']').clone().addClass('show-text').prepend(prependText));
        }
        if (status) {
          // add preview for current artwork
          $(`${formId} form .row`).prepend(_this.filePreviewHtml(this.src, file.name));
          $(`${formId}-section .tab-metainfo`).html(_this.fileMetaInfoHtml(t, file.size));
          // Enable submit
          _this.enableTemplateArea(templateType, formId);
          _this.enableSubmitButton(`${formId} button.theme-btn`);
          _this.directUploadArtworkFile(formId, file);// For direct upload artwork file
        } else {
          URL.revokeObjectURL(this.src);
        }
      };
      img.src = URL.createObjectURL(file);
    }
    return status;
  }

  // For generating file metainfo html
  fileMetaInfoHtml(file_type = '', file_size = '') {
    let html = '';
    if (file_type && file_size) {
      html = `<ul class="file_info_meta_ul">
                <li class="file_size">${this.formatBytes(file_size)}</li>
                <li class="file_type">${file_type} image</li>
              </ul>`;
    }
    return html;
  }

  // For convert file size bytes to humanize data string
  formatBytes(bytes = 0, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  // For generating file preview html
  filePreviewHtml(file_src = '', file_name = '') {
    let html = '';
    if (file_src && file_name) {
      html = `<div class="col-md-12">
                <img class="artwork_preview_img" src="${file_src}" alt="${file_name}" title="${file_name}"/>
              </div>`;
    }
    return html;
  }

  // For file upload error
  fileUploadErrorHtml(file = {}) {
    let html = '';
    if (file && file.name) {
      html = `<small class="show-text">
                We're sorry! We're unable to upload your ${file.name} artwork file on our server.
                Please try once more. If you're still unable to upload the file,
                please contact support.
              </small>`;
    }
    return html;
  }

  // For spinner of form
  renderSpinner(__this = null, is_active = true) {
    if (is_active) {
      __this.find('.spinner-section').show(); // activate loader
    } else {
      __this.find('.spinner-section').hide(); // de-activate loader
    }
  }

  // For direct upload artwork file
  directUploadArtworkFile(formId = '', file = {}) {
    const _this = this;
    _this.renderSpinner($(formId));// activate loader
    const url = $('#multi_files_input').data('app-direct-upload-url');
    const upload = new DirectUpload(file, url);
    upload.create((error, blob) => {
      if (error) {
        // Handle the error
        $(formId).html(_this.fileUploadErrorHtml(file));
        _this.renderSpinner($(formId), false);// de-activate loader
      } else {
        // Handle the success
        $(`${formId} form .row input.artwork_file_hidden_input`).val(blob.signed_id);
        $(`${formId} form .bulk-form-action-area button.theme-btn`).trigger('click');
      }
    });
  }
}
