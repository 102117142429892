import { Controller } from 'stimulus';
import { DirectUpload } from '@rails/activestorage';

export default class extends Controller {
  connect() {
    // Validate artwork form
    this.artworkFormValidator(this);
    // Check if the current user can edit the form
    if (this.data.get('may-edit') === 'true') return;

    // Disable all form fields
    $(this.element).find('input, select, textarea').attr('disabled', 'disabled');
  }

  // Validate artwork form
  artworkFormValidator(__this) {
    const form = document.getElementById('artwork-form');
    form.addEventListener('submit', (e) => {
      e.preventDefault();
      let error_explanation_html = '';
      const error_explanation = [];
      let status = true;
      const may_admin = (__this.data.get('may-admin') === 'true');

      const artwork_user_id = (may_admin) ? $('#artwork_user_id').val() : null;
      const artwork_name = $('#artwork_name').val();
      const artwork_tags = (may_admin) ? $('#artwork_tags').val() : null;
      const artwork_tags_arr = (artwork_tags != null && artwork_tags.trim() !== '') ? artwork_tags.trim().split(',') : [];
      const artwork_tags_data = artwork_tags_arr.filter(tags => tags != null && tags.trim() !== '');
      const artwork_description = $('#artwork_description').val();
      const artwork_file = $('#artwork_file').val();
      const artwork_regular_templates = $('#artwork_regular_templates').val();

      if (may_admin && (artwork_user_id == null || artwork_user_id.trim() === '')) {
        error_explanation.push("<li>Artwork User can't be blank</li>");
        status = false;
      }
      if (artwork_name == null || artwork_name.trim() === '') {
        error_explanation.push("<li>Name can't be blank</li>");
        status = false;
      }
      if (may_admin && (artwork_tags == null || artwork_tags.trim() === '')) {
        error_explanation.push("<li>Tags can't be blank</li>");
        status = false;
      }
      if (may_admin && artwork_tags_data.length < 3) {
        error_explanation.push('<li>Tags must contain three or more keywords');
        status = false;
      }
      if (artwork_description == null || artwork_description.trim() === '') {
        error_explanation.push("<li>Description can't be blank</li>");
        status = false;
      }
      if (artwork_file == null || artwork_file.trim() === '') {
        error_explanation.push("<li>File can't be blank</li>");
        status = false;
      }
      if (
        artwork_regular_templates == null
        || !Array.isArray(artwork_regular_templates)
        || artwork_regular_templates.length < 2
      ) {
        error_explanation.push('<li>Please choose two regular mockup scenes.</li>');
        status = false;
      }
      if (!status) {
        let total_errors = error_explanation.length;
        total_errors = `${total_errors} error${total_errors === 1 ? '' : 's'}`;
        error_explanation_html = `<h2>${total_errors} prohibited this artwork from being saved:</h2><ul>${error_explanation.join('')}</ul>`;
        window.scrollTo(0, 0); // scroll top
      } else {
        // If valid form
        this.directUploadArtworkFile($(this.element));// For direct upload artwork file
      }
      $('.error_explanation_section').html('');
      $('#js_error_explanation').html(error_explanation_html);
      return status;
    });
  }

  // For collect form data and call submitArtwork method
  processArtworkForm(__this = null) {
    const body_params = new FormData();// create form for artwork submission

    // Form data
    const form_data = __this.serializeArray();
    $.each(form_data, (key, input) => {
      if ((input.name).trim() !== 'artwork[file]') {
        if ((input.name).trim() === 'artwork[artwork_file_hidden_input]') {
          body_params.append('artwork[file]', input.value);// File data
        } else {
          body_params.append(input.name, input.value);
        }
      }
    });

    this.submitArtwork(body_params, __this); // call ajax for artwork creation
  }

  // Ajax for artwork creation
  submitArtwork(body_params = {}, __this = null) {
    const url = $('#artwork-form').attr('action');
    const token = document.getElementsByName('csrf-token')[0].content;
    this.renderSpinner(__this); // activate loader
    const _this = this;

    return $.ajax({
      type: 'POST',
      url,
      data: body_params,
      processData: false,
      contentType: false,
      headers: {
        'X-CSRF-Token': token,
      },
      success(data) {
        // if success
        _this.renderSpinner(__this, false); // de-activate loader
        return data;
      },
      error(data) {
        // if error
        if (data && data.status && data.status === 422 && data.responseJSON != null) {
          _this.renderErrorMessages(__this, data.responseJSON);
        } else if (data && data.status && data.status === 413) {
          alert("The file that you attempted to upload is too large. The current file size limit is 150MB. Please try uploading a smaller file. If you're having any issues or believe this to be an error, please reach out to us at support. Thank you!");
        } else {
          alert(JSON.stringify(data));
        }
        _this.renderSpinner(__this, false); // de-activate loader
        return false;
      },
    });
  }

  // For spinner of form
  renderSpinner(__this = null, is_active = true) {
    if (is_active) {
      __this.find('.spinner-section').show(); // activate loader
    } else {
      __this.find('.spinner-section').hide(); // de-activate loader
    }
  }

  // For render error messages
  renderErrorMessages(__this = null, error_object = {}) {
    let error_explanation = '';
    let total_errors = 0;

    for (const property in error_object) {
      let field = property.replace(/_/g, ' ');
      field = field.charAt(0).toUpperCase() + field.slice(1);
      for (const property1 in error_object[property]) {
        error_explanation = `${error_explanation}<li>${field} ${error_object[property][property1]}</li>`;
        total_errors++;
      }
    }

    if (total_errors) {
      total_errors = `${total_errors} error${total_errors === 1 ? '' : 's'}`;
      error_explanation = `<h2>${total_errors} prohibited this artwork from being saved:</h2><ul>${error_explanation}</ul>`;
      window.scrollTo(0, 0); // scroll top
    }
    __this.find('.error_explanation_section').html('');
    __this.find('.js_error_explanation').html(error_explanation);
  }

  // For file upload error
  renderDirectUploadErrorMessage(file = {}, __this = null) {
    let error_message = '';
    let file_name = '';
    if (file && file.name) {
      file_name = file.name;
    }
    error_message = `- We're sorry! We're unable to upload your ${file_name} artwork file on our server.
                      Please try once more. If you're still unable to upload the file,
                      please contact support.`;
    const error_object = { file: [error_message] };
    this.renderErrorMessages(__this, error_object);
  }

  // For direct upload artwork file
  directUploadArtworkFile(__this = null) {
    const _this = this;
    const artwork_file_hidden_input = $('#artwork_file_hidden_input').val();
    if (artwork_file_hidden_input == null || artwork_file_hidden_input.trim() === '') {
      // if direct upload process is pending for current file
      const artwork_file_input = document.getElementById('artwork_file');// get file from file input
      const file = artwork_file_input.files[0];
      _this.renderSpinner(__this);// activate loader
      const url = $('#artwork_file').data('app-direct-upload-url');
      const upload = new DirectUpload(file, url);
      upload.create((error, blob) => {
        if (error) {
          // Handle the error
          _this.renderDirectUploadErrorMessage(file, __this);
          _this.renderSpinner(__this, false);// de-activate loader
        } else {
          // Handle the success
          $('#artwork_file_hidden_input').val(blob.signed_id);
          _this.processArtworkForm(__this); // process form data
        }
      });
    } else {
      // if current file is already uploaded
      _this.processArtworkForm(__this); // process form data
    }
  }
}
