import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
  }

  toggle() {
    document.getElementById('left-menu-sidebar')
      .classList
      .toggle('active');
    document.getElementById('dashboard')
      .classList
      .toggle('active');
    document.getElementById('toggle-menu-trigger')
      .classList
      .toggle('toggle-menu-is-active');
    document.getElementsByTagName('body')[0]
      .classList
      .toggle('toggle-menu-body-is-active');
  }
}
