/* eslint-disable class-methods-use-this */
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.fileFieldValidate();
  }

  fileFieldValidate() {
    const fileUploadField = document.querySelector('.uplod-file-container');
    const fileNameTxt = document.querySelector('.uplod-file-container .upload-box-txt');
    const input = document.getElementById('enviroment-file-field-input');
    const _this = this;

    input.addEventListener('change', () => {
      fileUploadField.classList.remove('has-file-media');
      fileNameTxt.innerHTML = 'Drop your file here or <b>browse</b>';
      let status = false;
      if (input.files.length != 0) {
        status = true;
        fileUploadField.classList.add('has-file-media');
        fileNameTxt.innerHTML = _this.renderFileName(input, 40);
      }
      return status;
    });
  }

  validateAvatarFormField() {
    try {
      $('#avatar-form-action-btn').removeAttr('disabled');
      const input = document.getElementById('avatar-file-field');
      const input_media_text = $('#has-file-media-text');
      input.classList.remove('has-file-media');
      input_media_text.html('');
      let status = true;
      let error_message = '';
      if (input.files.length == 0) {
        // Check file exist
        status = false;
        error_message = `File can't be blank.`;
      } else {
        const file = input.files[0];
        const t = file.type.split('/')
          .pop()
          .toLowerCase();
        if (t !== 'png' && t !== 'jpeg') {
          // Check file type
          status = false;
          error_message = 'The image file must be of type png or jpeg.';
        } else if (file.size > (1048576 * 2)) {
          // Must be less than 2MB
          status = false;
          error_message = 'The image file is too large. Please ensure the file is less than or equal to 2MB.';
        }
      }

      $('#avatar-file-field-error').html(error_message);

      if (status) {
        input.classList.add('has-file-media');
        input_media_text.html(this.renderFileName(input, 40));
      }

      return status;
    } catch (err) {
      alert(err.message);
    }
  }

  renderFileName(input = null, text_limit = 40) {
    let file_name = input.value.split('\\').pop();
    if (file_name.length > text_limit)
      file_name = file_name.substring(0, text_limit) + '...';
    file_name = `Current File: <b>${file_name}</b></br>Drop your new file here or <b>browse</b>`;

    return file_name;
  }

}
