import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
  }

  call() {
    try {
      const _this = $(this.element);
      const input_selector = _this.data('toggle-passwords-field');
      if (input_selector != null && input_selector.trim() !== '') {
        const input = $(input_selector);
        const is_visible = (input.attr('type') === 'password') ? true : false;
        const type = (is_visible) ? 'text' : 'password';
        const trigger_class = (is_visible) ? 'fa-eye-slash' : 'fa-eye';
        input.attr('type', type);
        _this.removeClass('fa-eye fa-eye-slash').addClass(trigger_class);
      }
    } catch (err) {
      alert(err.message)
    }
  }

}
