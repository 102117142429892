import ApplicationController from './application_controller';

export default class extends ApplicationController {
  // For disable royalty sales report
  disableRoyaltySalesReport() {
    $('#royalties-total').html('');
    $('#report-download-btn').addClass('disabled').attr('disabled', 'disabled');
    $('#royalties-form-submit-btn').trigger('click');
  }

  // For validating for form
  validateForm(e) {
    e.preventDefault();

    let error_explanation_html = '';
    const error_explanation = [];
    const starting_date = $('#starting_date').val();
    const ending_date = $('#ending_date').val();
    let status = true;

    if (starting_date == null || starting_date.trim() === '') {
      error_explanation.push("<li>Start time can't be blank</li>");
      status = false;
    }
    if (ending_date == null || ending_date.trim() === '') {
      error_explanation.push("<li>End time can't be blank</li>");
      status = false;
    }
    if (status && ((new Date(starting_date)).getTime()) >= ((new Date(ending_date)).getTime())) {
      error_explanation.push('<li>End time must be greater than start time</li>');
      status = false;
    }

    if (!status) {
      // if invalid form
      let total_errors = error_explanation.length;
      total_errors = `${total_errors} error${total_errors === 1 ? '' : 's'}`;
      error_explanation_html = `<h2>${total_errors} prohibited this royalty sales report from being processed:</h2><ul>${error_explanation.join('')}</ul>`;
    } else {
      // if valid form
      this.renderRoyaltiesSalesReport();// For render royalties sales reports response
    }

    $('.error_explanation_section').html('');
    $('#js_error_explanation').html(error_explanation_html);
    return status;
  }

  // For render royalties sales reports response
  renderRoyaltiesSalesReport() {
    const _this = this;
    _this.renderSpinner();// activate loader

    // Call stimulus reflex for rayalties sales report
    this.stimulate('RoyaltiesReflex#render_royalties_sales_report').then(() => {
      if ($('#royalties-total').html().trim() != '') {
        $('#report-download-btn').removeClass('disabled').removeAttr('disabled');
      }
      _this.renderSpinner(false);// de-activate loader
    });
  }

  // For validating end date field
  validateEndingDate(e) {
    e.preventDefault();
    const _this = $('#ending_date');
    const starting_date = $('#starting_date').val();
    const ending_date = _this.val();

    let starting_date_obj = new Date(starting_date);// Create new Date instance
    starting_date_obj.setDate(starting_date_obj.getDate() + 1);// add a day
    starting_date_obj = starting_date_obj.toISOString().slice(0, 10);

    _this.attr('min', starting_date_obj);

    if (starting_date.trim() != '' && ending_date.trim() != '' && ((new Date(starting_date)).getTime()) >= ((new Date(ending_date)).getTime())) {
      _this.val(starting_date_obj);
    }
    this.disableRoyaltySalesReport();// disable royalty sales report
  }

  // For download Royalty Sales Report
  downloadRoyaltySalesReport(e) {
    e.preventDefault();
    try {
      const _this = $('#report-download-btn');
      const starting_date = $('#starting_date').val();
      const ending_date = $('#ending_date').val();
      const __this = this;

      __this.renderSpinner();// activate loader
      const link = `${_this.data('link')}?starting_date=${starting_date}&ending_date=${ending_date}`;
      const token = document.getElementsByName('csrf-token')[0].content;
      $.ajax({
        url: link,
        type: 'GET',
        processData: false,
        contentType: false,
        headers: {
          'X-CSRF-Token': token,
        },
        success(data, status, xhr) {
          const blob = new Blob([data]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = __this.getFileName(xhr);// get file name from ajax response
          link.click();

          __this.renderSpinner(false);// de-activate loader
        },
        error(error, exception) {
          alert('Something went wrong. Please try again.');
          __this.renderSpinner(false);// de-activate loader
        },
      });
    } catch (err) {
      // console.log(err.message);
      alert('Something went wrong. Please try again.');
      __this.renderSpinner(false);// de-activate loader
    }
  }

  // For get file name from ajax response
  getFileName(xhr) {
    let filename = 'Royalty_sales_report.csv';
    const disposition = xhr.getResponseHeader('Content-Disposition');
    if (disposition && disposition.indexOf('attachment; filename="') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
        filename = decodeURIComponent(filename);
      }
    }
    return filename;
  }

  // For spinner of form
  renderSpinner(is_active = true) {
    const spinner = $('#royalties-form .spinner-section');
    if (is_active) {
      spinner.show(); // activate loader
    } else {
      spinner.hide(); // de-activate loader
    }
  }
}
