import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.renderStates();
  }

  renderStates() {
    const country = $('#user_payment_detail_bank_country').val();
    if (!country) { return; }

    const url = this.data.get('states-url');
    $.get(`${url}?country=${country}`, (data) => {
      $('#user_payment_detail_bank_state').html(data.states);
    });
    this.selectState(country);
  }

  selectState(country) {
    setTimeout(() => {
      const state = $('#user_payment_detail_bank_state').data('state');
      if (!country) { return; }

      $(`#user_payment_detail_bank_state option[value="${state}"]`).attr('selected', 'selected');
    }, 500);
  }
}
