import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
  }

  // For initialize tabs
  initializeTabs() {
    if ($('div ul#tabs').attr('data-total-tabs') != null) return false;
    $('#multi-files-input-section').hide();
    $('#bulk-artwork-submission-section').show();
    $('#single-form [id]').each(function (index) {
      $(this).attr('data-id', $(this).attr('id')).removeAttr('id');
    });
    const _form = this.getArtworkForm();
    $('#tabs li div.tab-wrapper-main:not(:first)').addClass('inactive');
    $('.tabs-data-container').hide();
    $('.tabs-data-container:first').html(_form).show();
    $('div ul#tabs').attr('data-total-tabs', $('div ul#tabs li.tabs-toggle-section').length);
    this.activateTab();
  }

  // For add new tabs
  addNewTabs(e) {
    if ($('div ul#tabs').attr('data-total-tabs') == null) {
      this.initializeTabs();
    } else {
      const num_tabs = parseInt($('div ul#tabs').attr('data-total-tabs')) + 1;
      $('#tabs li div.tab-wrapper-main').addClass('inactive');
      $('div ul#tabs').append(this.newTabHtml(num_tabs));// rendering new tab html
      $('.tabs-data-container:not(:last)').hide();
      $('div ul#tabs').attr('data-total-tabs', num_tabs);
      this.activateTab(`#tab${num_tabs}C`);
    }
  }

  // For generating new tab html
  newTabHtml(num_tabs = 0) {
    let html = '';
    if (num_tabs) {
      const _form = this.getArtworkForm();
      const dummy_artwork_image_src = $('#tab1 .dummy-artwork-icon').attr('src');
      html = `<li class="tabs-toggle-section" id="tab${num_tabs}C-section">
                <div class="tabs-toggle-container">
                  <div id="tab${num_tabs}" class="tab-wrapper-main">
                    <div class="tab-wrapper col-md-12">
                      <div class="tab-wrapper-left text-left col-md-6">
                        <div 
                          class="tab-icon-left"
                          data-controller="bulk-artwork-tabs"
                          data-action="click->bulk-artwork-tabs#toggleTab"
                          data-id-target="tab${num_tabs}" 
                        >
                          <img class="dummy-artwork-icon" src="${dummy_artwork_image_src}" width="50" height="50"/>
                        </div>
                        <div class="tab-description">
                          <span class="tab-index">Artwork ${num_tabs}</span>
                          <span class="tab-metainfo"></span>
                        </div>                 
                      </div>
                      <div class="tab-wrapper-right text-right col-md-6">
                        <ul>
                          <li class="status-icon" data-status=""></li>
                          <li class="remove-icon">
                            <button 
                              class="remove-tab" 
                              data-tab-id="${num_tabs}" 
                              data-controller="bulk-artwork-tabs" 
                              data-action="click->bulk-artwork-tabs#removeTab" 
                              title="Remove"
                            >
                              <i class="fa fa-trash-o" aria-hidden="true"></i>
                            </button>
                          </li>
                          <li 
                            class="arrow-icon"
                            data-controller="bulk-artwork-tabs"
                            data-action="click->bulk-artwork-tabs#toggleTab"
                            data-id-target="tab${num_tabs}" 
                          >
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                    </div>                    
                  </a>
                </div>
                <div class="tabs-data-container" id="tab${num_tabs}C">${_form}</div>
              </li>`;
    }
    return html;
  }

  // For switching between tabs
  toggleTab(e) {
    const t = $(this.element).data('id-target');
    if (t == null) return false;

    const _this = $(`#${t}`);
    const tab_active = (_this.hasClass('inactive'))
    $('#tabs li div.tab-wrapper-main').addClass('inactive');
    $('.tabs-data-container').hide();
    if (tab_active) {
      _this.removeClass('inactive');
      $(`#${t}C`).fadeIn('slow');
      this.activateTab(`#${t}C`);
    } else {
      $('.tabs-toggle-section').removeClass('is-active-toggle-section');
      $('.tabs-toggle-section .tabs-toggle-container .tab-wrapper .tab-wrapper-right .arrow-icon i').removeClass('fa-angle-up').addClass('fa-angle-down');
      $('#tabs-container .tabs-data-container [id]').each(function (index) {
        $(this).attr('data-id', $(this).attr('id')).removeAttr('id');
      });
    }
  }

  // For removing tabs
  removeTab(e) {
    const _this = this.element;
    const t = $(_this).attr('data-tab-id');
    if (t == null) return false;
    if (confirm(`Are you sure you want to remove this "Artwork ${t}" section from current session?`)) {
      if (!$(`#tab${t}`).hasClass('inactive')) $(`#tab${parseInt(t) - 1} .tab-icon-left`).trigger('click');
      $(`#tab${t}`).parent().remove();
      $(`#tab${t}C`).remove();
    }
  }

  // For artwork form
  getArtworkForm() {
    return $('#single-form').html();
  }

  // For activating current tabs
  activateTab(tab = '#tab1C') {
    $('.tabs-toggle-section').removeClass('is-active-toggle-section');
    $(`${tab}-section`).addClass('is-active-toggle-section');
    $('.tabs-toggle-section .tabs-toggle-container .tab-wrapper .tab-wrapper-right .arrow-icon i').removeClass('fa-angle-up').addClass('fa-angle-down');
    $(`${tab}-section .tabs-toggle-container .tab-wrapper .tab-wrapper-right .arrow-icon i`).addClass('fa-angle-up').removeClass('fa-angle-down');
    $(`${tab}-section`).attr('data-tab-id', tab);
    $('#tabs-container .disabled-picker').removeClass('disabled-picker');
    $(`${tab} .template-type-select select`).attr('data-tab-id', tab);
    $('#tabs-container .tabs-data-container [id]').each(function (index) {
      $(this).attr('data-id', $(this).attr('id')).removeAttr('id');
    });
    $(`${tab} [data-id]`).each(function (index) {
      $(this).attr('id', $(this).attr('data-id')).removeAttr('data-id');
    });
    $('html, body').animate({
      scrollTop: $(`${tab}-section`).position().top,
    });
  }
}
