import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.renderPickers();
  }

  // Destroy pickers
  destroyPickers() {
    try {
      // Destroy previous pickers
      $.each($('select.image-picker:not(.disabled-picker)'), (index, value) => {
        $(value)
          .data('picker')
          .destroy();
      });
    } catch (err) {
      // console.log(err.message);
    }
  }

  renderPickers() {
    this.destroyPickers();
    $('select.image-picker:not(.disabled-picker)')
      .imagepicker({
        // hide_select:  false,
        limit_reached() {
          alert('You only need to choose two scenes.');
        },
        selected() {
          $(this)
            .data('picker')
            .sync_picker_with_select();
        },
      });
  }

  rerenderPickers() {
    this.destroyPickers();

    // Get templates data from endpoint
    const _this = this;
    const __this = this.element;
    let formId = $(__this).attr('data-tab-id');
    formId = (formId == null) ? '' : formId;
    const type = (formId == '') ? $('select#artwork_template_type option:selected').val() : $(`${formId} .template-type-select select`).val();

    $.get(`/environment_templates/templates?type=${type}`, (data) => {
      // Rerender select element and options

      // Regular templates
      let options = [];
      $.each(data.regular, (index, value) => {
        options.push(`<option value="${value.id}" data-img-src="${value.image_url}"></option>`);
      });
      const $regular = (formId == '') ? $('select#artwork_regular_templates') : $(`${formId} .regular-templates-select select`);
      $regular.find('option').remove();
      $regular.append(options.join(''));

      // Model templates
      options = [];
      $.each(data.model, (index, value) => {
        options.push(`<option value="${value.id}" data-img-src="${value.image_url}"></option>`);
      });
      const $model = (formId == '') ? $('select#artwork_model_templates') : $(`${formId} .model-templates-select select`);
      $model.find('option').remove();
      $model.append(options.join(''));

      // Rerender pickers
      _this.renderPickers();
    });
  }
}
