import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.fileFieldValidate();
    this.validateAvatarForm();
  }

  fileFieldValidate() {
    const input = document.getElementById('avatar-file-field');
    const _this = this;

    input.addEventListener('change', () => {
      return _this.validateAvatarFormField();
    });
  }

  validateAvatarFormField() {
    try {
      $('#avatar-form-action-btn').removeAttr('disabled');
      const input = document.getElementById('avatar-file-field');
      const input_media_text = $('#has-file-media-text');
      input.classList.remove('has-file-media');
      input_media_text.html('');
      let status = true;
      let error_message = '';
      if (input.files.length == 0) {
        // Check file exist
        status = false;
        error_message = `File can't be blank.`;
      } else {
        const file = input.files[0];
        const t = file.type.split('/')
          .pop()
          .toLowerCase();
        if (t !== 'png' && t !== 'jpeg') {
          // Check file type
          status = false;
          error_message = 'The image file must be of type png or jpeg.';
        } else if (file.size > (1048576 * 2)) {
          // Must be less than 2MB
          status = false;
          error_message = 'The image file is too large. Please ensure the file is less than or equal to 2MB.';
        }
      }

      $('#avatar-file-field-error').html(error_message);

      if (status) {
        input.classList.add('has-file-media');
        input_media_text.html(this.renderFileName(input));
      }

      return status;
    } catch (err) {
      alert(err.message);
    }
  }

  renderFileName(input = null, text_limit = 40) {
    let file_name = input.value.split('\\').pop();
    if (file_name.length > text_limit)
      file_name = file_name.substring(0, text_limit) + '...';
    return file_name;
  }

  validateAvatarForm() {
    const form = document.getElementById('edit_user');
    const _this = this;

    form.addEventListener('submit', (e) => {
      if (_this.validateAvatarFormField() !== true) {
        e.preventDefault();
      }
    });
  }
}
