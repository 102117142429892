import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    // Validate background image upload form
    this.backgroundImageFileUploader();
    this.fileFieldValidate();
  }

  fileFieldValidate() {
    const input = document.getElementById('image_file_input');
    const _this = this;

    input.addEventListener('change', () => {
      return _this.validateAppSettingFormField();
    });
  }

  validateAppSettingFormField(trigger_form_submit = false) {
    try {
      const image_file_input = $('#image_file_input');
      const input_media_text = $('#has-file-media-text');
      let status = true;
      let error_message = '';
      $('#file-input-err').html('');
      image_file_input.removeClass('has-file-media');
      input_media_text.html('');

      if (image_file_input.val() == null || image_file_input.val().trim() === '') {
        status = false;
        error_message = "File can't be blank.";
      } else {
        const image_file_type = image_file_input[0].files[0].type.split('/')
          .pop()
          .toLowerCase();
        // Check file type
        if (image_file_type !== 'png' && image_file_type !== 'jpeg') {
          status = false;
          error_message = 'The image file must be of type png or jpeg.';
        } else if (image_file_input[0].files[0].size < 1048576) { //  minimum 1MB
          status = false;
          error_message = 'The image file is too small. Please ensure the file is greater than or equal to 1MB.';
        }
      }

      if (status) {
        this.validateImageDemensions(image_file_input, trigger_form_submit);
      } else {
        $('#file-input-err').html(error_message);
      }

      return status;
    } catch (err) {
      alert(err.message);
    }
  }

  // For Validate background image upload form
  backgroundImageFileUploader() {
    const form_submit_button = document.getElementById('submit_button');
    const _this = this;
    form_submit_button.addEventListener('click', (event) => {
      return _this.validateAppSettingFormField(true);
    });
  }

  // For Validate Image Demensions
  // eslint-disable-next-line class-methods-use-this
  validateImageDemensions(image_file_input = '', trigger_form_submit = false) {
    let error_message = '';
    const input = document.getElementById('image_file_input');
    const input_media_text = $('#has-file-media-text');
    const _this = this;

    const window_url = window.URL || window.webkitURL;
    let img;
    const file = image_file_input[0].files[0];
    if (file) {
      img = new Image();
      const objectUrl = window_url.createObjectURL(file);
      img.onload = function () {
        window_url.revokeObjectURL(objectUrl);
        if (this.width >= 719 && this.height >= 750) {
          // valid file for page background image
          if (trigger_form_submit) $('#background_image_upload_form').submit(); // submit current form
          input.classList.add('has-file-media');
          input_media_text.html(_this.renderFileName(input));
        } else {
          error_message = 'The ratio for your image must be at least 719px in width and 750px in height.';
        }
        $('#file-input-err').html(error_message);
      };
      img.src = objectUrl;
    }
  }

  renderFileName(input = null, text_limit = 40) {
    let file_name = input.value.split('\\').pop();
    if (file_name.length > text_limit)
      file_name = file_name.substring(0, text_limit) + '...';
    return file_name;
  }

}
