import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
  }

  // For scrolling between image picker items (previous)
  previous(e) {
    const _this = this.element;
    const t = $(_this).data('scroll-target');
    if (t == null) return false;

    const container = document.querySelector(`${t}`);
    this.sideScroll(container, 'left');
  }

  // For scrolling between image picker items (next)
  next(e) {
    const _this = this.element;
    const t = $(_this).data('scroll-target');
    if (t == null) return false;

    const container = document.querySelector(`${t}`);
    this.sideScroll(container, 'right');
  }

  // For scrolling
  sideScroll(element, direction = 'right', speed = 25, distance = 100, step = 10) {

    let scrollAmount = 0;

    const slideTimer = setInterval(function () {
      if (direction == 'left') {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);

  }

}
