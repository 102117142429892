import { Controller } from 'stimulus';
import SignaturePad  from 'signature_pad';

export default class extends Controller {
  connect() {
    this.addSignaturePads();
  }

  addSignaturePads() {
    const _this = this;
    $('canvas.signature_canvas').each((index, value) => {
      const canvas = value;
      const $canvas = $(value);
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      const signaturePad = new SignaturePad(
        canvas,
        {
          onEnd: () => _this.handlePopulateSignatureData(canvas, signaturePad),
        },
      );

      signaturePad.fromDataURL($canvas.data('previous'));
      window.onresize = _this.resizeCanvas(canvas, signaturePad);
      _this.resizeCanvas(canvas, signaturePad);

      $canvas.siblings('.signature_pad_reset').on('click', (event) => {
        event.preventDefault();
        signaturePad.clear();
        $canvas.siblings('.signature_pad_input').val('');
      });
    });
  }

  resizeCanvas(canvas, signaturePad) {
    const ratio =  Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  }

  handlePopulateSignatureData(canvas, signaturePad) {
    const data = signaturePad.toDataURL();
    $(canvas).siblings('.signature_pad_input').val(data);
  }
}
