import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    const chart_type = $(this.element).data('canvas-js-chart-type');
    if (chart_type == null) return false;

    switch (chart_type) {
      case 'day':
        this.renderDayChart();
        break;
      case 'week':
        this.renderWeekChart();
        break;
      case 'month':
        this.renderMonthChart();
        break;
      case 'year':
        this.renderYearChart();
        break;
      case 'custom_range':
        this.renderCustomRangeChart();
        break;
      default:
        alert('chart type invalid!');
        break;
    }
    return true;
  }

  chartTitle(chart_type = '') {
    return {
      // text: `Royalties Overview by ${chart_type}`,
      text: '',
    }
  }

  chartToolTip() {
    return {
      backgroundColor: '#0c1531',
      fontColor: '#ffffff',
      borderColor: '#0c1531',
      content: '${y}<br><span class="canvas-chart-gray-tooltip">{x_label}</span>',
    }
  }

  // eslint-disable-next-line class-methods-use-this
  chartDataPoints(chart_type = 'Week', chart_data_points_element = '#chartContainerWeekDataPoints') {
    let chartDataPoints = [];

    let chartDataPointsElement = $(chart_data_points_element).val();
    if (chartDataPointsElement && chartDataPointsElement != null) {
      let i = 0;
      chartDataPointsElement = JSON.parse(chartDataPointsElement);
      chartDataPointsElement = (chart_type == 'Week' || chart_type == 'CustomRange') ? chartDataPointsElement[0] : chartDataPointsElement;
      (chartDataPointsElement).forEach((entry) => {
        if (entry.x && entry.x != null) {
          chartDataPointsElement[i].x = this.convertTZ(entry.x, chart_type);
        }
        i++;
      });
      chartDataPoints = chartDataPointsElement;
    }
    console.log(`${chart_type} DataPoints=>`, chartDataPoints);

    return chartDataPoints;
  }

  convertTZ(date, chart_type = 'Week') {
    return (chart_type == 'Week') ? new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: 'Canada/Pacific' })) : new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US"));
  }

  // For day chart
  renderDayChart() {
    const chart = new CanvasJS.Chart('chartContainerDay',
      {
        title: this.chartTitle('Day'),
        axisX: {
          interval: 2,
          intervalType: 'hour',
        },
        toolTip: this.chartToolTip(),
        data: [
          {
            type: 'line',
            dataPoints: this.chartDataPoints('Day', '#chartContainerDayDataPoints'),
          }
        ]
      });
    chart.render();
  }

  // For week chart
  renderWeekChart() {
    const chart = new CanvasJS.Chart('chartContainerWeek',
      {
        title: this.chartTitle('Week'),
        axisX: {
          interval: 1,
          intervalType: 'day',
        },
        toolTip: this.chartToolTip(),
        data: [
          {
            type: 'line',
            dataPoints: this.chartDataPoints('Week', '#chartContainerWeekDataPoints'),
          }
        ]
      });

    chart.render();
  }

  // For month chart
  renderMonthChart() {
    const chart = new CanvasJS.Chart('chartContainerMonth',
      {
        title: this.chartTitle('Month'),
        axisX: {
          interval: 1,
          intervalType: 'month',
        },
        toolTip: this.chartToolTip(),
        data: [
          {
            type: 'line',
            dataPoints: this.chartDataPoints('Month', '#chartContainerMonthDataPoints'),
          }
        ]
      });

    chart.render();
  }

  // For year chart
  renderYearChart() {
    const chart = new CanvasJS.Chart('chartContainerYear',
      {
        title: this.chartTitle('Year'),
        axisX: {
          interval: 1,
          intervalType: 'year',
        },
        toolTip: this.chartToolTip(),
        data: [
          {
            type: 'line',
            dataPoints: this.chartDataPoints('Year', '#chartContainerYearDataPoints'),
          }
        ]
      });

    chart.render();
  }

  // For custom_range chart
  renderCustomRangeChart() {
    const chart = new CanvasJS.Chart('chartContainerCustomRange',
      {
        title: this.chartTitle('Custom Range'),
        toolTip: this.chartToolTip(),
        data: [
          {
            type: 'line',
            dataPoints: this.chartDataPoints('CustomRange', '#chartContainerCustomRangeDataPoints'),
          }
        ]
      });

    chart.render();
  }

}
