import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    // Check if the current user can edit the form
    if (this.data.get('may-edit') === 'true') return;

    // Disable all form fields
    $(this.element).find('input, select, textarea').attr('disabled', 'disabled');
  }

  // For artwork file uploader
  artworkFileUploader(e) {
    e.preventDefault();
    const __this = $(this.element);
    let error_explanation_html = '';
    const error_explanation = [];
    let status = true;
    const artwork_file = __this.find('.artwork_file_hidden_input').val();

    if (artwork_file == null || artwork_file.trim() === '') {
      error_explanation.push("<li>File can't be blank</li>");
      status = false;
    }

    if (!status) {
      let total_errors = error_explanation.length;
      total_errors = `${total_errors} error${total_errors === 1 ? '' : 's'}`;
      error_explanation_html = `<h2>${total_errors} prohibited this artwork from being saved:</h2><ul>${error_explanation.join('')}</ul>`;
      $('html, body').animate({
        scrollTop: __this.closest('li.tabs-toggle-section').position().top,
      });// scroll to active form
    }

    __this.find('.error_explanation_section').html('');
    __this.find('.js_error_explanation').html(error_explanation_html);

    if (status) {
      // If valid form
      this.processArtworkForm(__this, true); // process form data
    }
    return status;
  }

  // Validation rules for artwork form
  artworkFormValidator(e) {
    e.preventDefault();
    const __this = $(this.element);
    let error_explanation_html = '';
    const error_explanation = [];
    let status = true;
    const may_admin = (__this.data('bulk-artwork-submission-may-admin') === 'true');

    const artwork_user_id = (may_admin) ? $('#artwork_user_id').val() : null;
    const artwork_name = $('#artwork_name').val();
    const artwork_tags = (may_admin) ? $('#artwork_tags').val() : null;
    const artwork_tags_arr = (artwork_tags != null && artwork_tags.trim() !== '') ? artwork_tags.trim().split(',') : [];
    const artwork_tags_data = artwork_tags_arr.filter(tags => tags != null && tags.trim() !== '');
    const artwork_description = $('#artwork_description').val();
    const artwork_file = $('#artwork_file').val();
    const artwork_regular_templates = $('#artwork_regular_templates').val();

    if (may_admin && (artwork_user_id == null || artwork_user_id.trim() === '')) {
      error_explanation.push("<li>Artwork User can't be blank</li>");
      status = false;
    }
    if (artwork_name == null || artwork_name.trim() === '') {
      error_explanation.push("<li>Name can't be blank</li>");
      status = false;
    }
    if (may_admin && (artwork_tags == null || artwork_tags.trim() === '')) {
      error_explanation.push("<li>Tags can't be blank</li>");
      status = false;
    }
    if (may_admin && artwork_tags_data.length < 3) {
      error_explanation.push('<li>Tags must contain three or more keywords');
      status = false;
    }
    if (artwork_description == null || artwork_description.trim() === '') {
      error_explanation.push("<li>Description can't be blank</li>");
      status = false;
    }
    if (artwork_file == null || artwork_file.trim() === '') {
      error_explanation.push("<li>File can't be blank</li>");
      status = false;
    }
    if (
      artwork_regular_templates == null
      || !Array.isArray(artwork_regular_templates)
      || artwork_regular_templates.length < 2
    ) {
      error_explanation.push('<li>Please choose two regular mockup scenes.</li>');
      status = false;
    }
    if (!status) {
      let total_errors = error_explanation.length;
      total_errors = `${total_errors} error${total_errors === 1 ? '' : 's'}`;
      error_explanation_html = `<h2>${total_errors} prohibited this artwork from being saved:</h2><ul>${error_explanation.join('')}</ul>`;
      $('html, body').animate({
        scrollTop: $('#artwork-form').closest('li.tabs-toggle-section').position().top,
      });// scroll to active form
    }

    $('#artwork-form .error_explanation_section').html('');
    $('#js_error_explanation').html(error_explanation_html);

    if (status) {
      // If valid form
      this.processArtworkForm(__this); // process form data
    }
    return status;
  }

  // For collect form data and call submitArtwork method
  processArtworkForm(__this = null, is_only_file_upload_process = false) {
    const body_params = new FormData();// create form for artwork submission

    // Form data
    const form_data = __this.serializeArray();
    $.each(form_data, (key, input) => {
      if ((input.name).trim() != 'authenticity_token') {
        if (is_only_file_upload_process && (input.name).trim() == 'artwork[file]') {
          body_params.append(input.name, input.value);
        } else if (!is_only_file_upload_process && (input.name).trim() != 'artwork[file]') {
          body_params.append(input.name, input.value);
        }
      }
    });

    this.submitArtwork(body_params, __this, is_only_file_upload_process); // call ajax for artwork creation
  }

  // For spinner of form
  renderSpinner(__this = null, is_active = true) {
    if (is_active) {
      __this.find('.spinner-section').show(); // activate loader
    } else {
      __this.find('.spinner-section').hide(); // de-activate loader
    }
  }

  // For render error messages
  renderErrorMessages(__this = null, error_object = {}) {
    let error_explanation = '';
    let total_errors = 0;

    for (const property in error_object) {
      let field = property.replace(/_/g, ' ');
      field = field.charAt(0).toUpperCase() + field.slice(1);
      for (const property1 in error_object[property]) {
        error_explanation = `${error_explanation}<li>${field} ${error_object[property][property1]}</li>`;
        total_errors++;
      }
    }

    if (total_errors) {
      total_errors = `${total_errors} error${total_errors === 1 ? '' : 's'}`;
      error_explanation = `<h2>${total_errors} prohibited this artwork from being saved:</h2><ul>${error_explanation}</ul>`;
      $('html, body').animate({
        scrollTop: __this.closest('li.tabs-toggle-section').position().top,
      });// scroll to active form
    }
    __this.find('.error_explanation_section').html('');
    __this.find('.js_error_explanation').html(error_explanation);
  }

  // For generating approved message Html
  approvedMessageHtml(data_object = {}) {
    let html = '';
    if (data_object && data_object.artworkURL) {
      html = `<div class="g-row bulk-form-approved">
                <h5 class="alert alert-success">
                  <i aria-hidden="true" class="fa fa-check"></i>
                  Your Artwork was successfully created and approved.
                </h5>
                <p class="bulk-form-action-note">
                      It takes some time to render your previews. please wait a few minutes and <a href="${data_object.artworkURL}" target="_blank">click here</a> to check your artwork status. If the artwork isn't showing after 10 minutes, please contact support.
                </p>
              </div>`;
    }
    return html;
  }

  // For generating draft message Html
  draftMessageHtml(data_object = {}) {
    let html = '';
    if (data_object && data_object.artworkURL) {
      html = `<div class="col-sm-12 col-md-12 bulk-form-drafted">
                <div class="white-box-border">
                  <div class="text-center">
                    <h5 class="alert alert-success">
                      <i aria-hidden="true" class="fa fa-check"></i>
                      Your Artwork was successfully created as draft.
                    </h5>
                    <h3>Submit for Review</h3>
                    <p class="text-left">
                      If the above preview looks good, please click on the button to submit your artwork for review. Once submitted,
                      you will not be able to make any edits to the submission.
                    </p>
                    <a 
                      class="theme-btn text-center" 
                      href="javascript:void(0)" 
                      rel="nofollow" 
                      data-method="put" 
                      data-href="${data_object.artworkURL}" 
                      data-controller="bulk-artwork-submission" 
                      data-action="click->bulk-artwork-submission#submitForReview"
                    >
                      SUBMIT FOR REVIEW
                    </a>
                  </div>
                </div>
              </div>`;
    }
    return html;
  }

  // For generating review message Html
  reviewMessageHtml(data_object = {}) {
    let html = '';
    if (data_object && data_object.artworkURL) {
      html = `<div class="g-row bulk-form-reviewing">
                <h5 class="alert alert-success">
                  <i aria-hidden="true" class="fa fa-check"></i>
                  Your Artwork was successfully submitted for review.
                </h5>
                <span class="bulk-form-action-note">
                      Please <a href="${data_object.artworkURL}" target="_blank">click here</a> to check your artwork status.
                </span>
              </div>`;
    }
    return html;
  }

  // For generating pending form submission message Html
  pendingFormSubmissionMessageHtml(data_object = {}) {
    let html = '';
    if (data_object && data_object.artworkURL) {
      html = `<div class="g-row bulk-form-pending-form-submission">
                <h5 class="alert alert-success">
                  <i aria-hidden="true" class="fa fa-check"></i>
                  Your Artwork file was successfully uploaded.
                </h5>
              </div>`;
    }
    return html;
  }

  // For render success messages
  renderSuccessMessages(__this = null, data_object = {}) {
    let artwork_status = 'error';
    switch (data_object.responseJSON.status.trim()) {
      case 'pending_form_submission':
        // pending form submission block
        __this.find('.bulk-form-action-area').prepend(this.pendingFormSubmissionMessageHtml(data_object));
        __this.attr('action', data_object.artworkURL);
        __this.attr('data-action', 'submit->bulk-artwork-submission#artworkFormValidator');
        artwork_status = 'pending';
        break;
      case 'approved':
        // approved block
        __this.html(this.approvedMessageHtml(data_object));
        artwork_status = 'approved';
        break;
      case 'draft':
        // draft block
        __this.find('.bulk-form-action-area').html(this.draftMessageHtml(data_object));
        __this.find('input, select, textarea').attr('disabled', 'disabled');// Disable all form fields
        artwork_status = 'draft';
        break;
      case 'review':
        // review block
        __this.find('.bulk-form-action-area').html(this.reviewMessageHtml(data_object));
        __this.find('input, select, textarea').attr('disabled', 'disabled');// Disable all form fields
        artwork_status = 'review';
        break;
      default:
        // default block
        alert(JSON.stringify(data_object));
        break;
    }
    
    // update status tag
    $(`#${__this.closest('.tabs-data-container').attr('id')}-section .status-icon`).html(artwork_status.charAt(0).toUpperCase() + artwork_status.slice(1)).attr('data-status', artwork_status);

    __this.find('.error_explanation_section').html('');
  }

  // Ajax for artwork creation
  submitArtwork(body_params = {}, __this = null, is_only_file_upload_process = false) {
    const url = (is_only_file_upload_process) ? '/bulk_artwork' : __this.attr('action');
    const token = document.getElementsByName('csrf-token')[0].content;
    this.renderSpinner(__this); // activate loader
    const _this = this;

    return $.ajax({
      type: ((is_only_file_upload_process) ? 'POST' : 'PUT'),
      url,
      data: body_params,
      processData: false,
      contentType: false,
      headers: {
        'X-CSRF-Token': token,
      },
      success(data) {
        // if success
        if (data && data.responseJSON != null && data.responseJSON.id && data.responseJSON.status) {
          _this.renderSuccessMessages(__this, data);
        } else {
          alert(JSON.stringify(data));
        }
        _this.renderSpinner(__this, false); // de-activate loader
        return data;
      },
      error(data) {
        // if error
        if (data && data.status && data.status == 422 && data.responseJSON != null) {
          _this.renderErrorMessages(__this, data.responseJSON);
        } else if (data && data.status && data.status == 413) {
          alert("The file that you attempted to upload is too large. The current file size limit is 150MB. Please try uploading a smaller file. If you're having any issues or believe this to be an error, please reach out to us at support. Thank you!");
        } else {
          alert(JSON.stringify(data));
        }
        _this.renderSpinner(__this, false); // de-activate loader
        return false;
      },
    });
  }

  // For artwork submition for review
  submitForReview(e) {
    const url = $(this.element).data('href');
    const __this = $(this.element).parentsUntil('form');
    const token = document.getElementsByName('csrf-token')[0].content;
    this.renderSpinner(__this); // activate loader
    const _this = this;

    return $.ajax({
      type: 'PUT',
      url,
      processData: false,
      contentType: false,
      headers: {
        'X-CSRF-Token': token,
      },
      success(data) {
        // if success
        if (data && data.responseJSON != null && data.responseJSON.id && data.responseJSON.status) {
          _this.renderSuccessMessages(__this, data);
        } else {
          alert(JSON.stringify(data));
        }
        _this.renderSpinner(__this, false); // de-activate loader
        return data;
      },
      error(data) {
        // if error
        if (data && data.status && data.status == 422 && data.responseJSON != null) {
          _this.renderErrorMessages(__this, data.responseJSON);
        } else {
          alert(JSON.stringify(data));
        }
        _this.renderSpinner(__this, false); // de-activate loader
        return false;
      },
    });
  }
}
